exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acties-js": () => import("./../../../src/pages/acties.js" /* webpackChunkName: "component---src-pages-acties-js" */),
  "component---src-pages-ergoeduitzien-jeugdbrillen-js": () => import("./../../../src/pages/ergoeduitzien/jeugdbrillen.js" /* webpackChunkName: "component---src-pages-ergoeduitzien-jeugdbrillen-js" */),
  "component---src-pages-ergoeduitzien-js": () => import("./../../../src/pages/ergoeduitzien.js" /* webpackChunkName: "component---src-pages-ergoeduitzien-js" */),
  "component---src-pages-ergoeduitzien-sportbrillen-js": () => import("./../../../src/pages/ergoeduitzien/sportbrillen.js" /* webpackChunkName: "component---src-pages-ergoeduitzien-sportbrillen-js" */),
  "component---src-pages-ergoeduitzien-volwassenenbrillen-js": () => import("./../../../src/pages/ergoeduitzien/volwassenenbrillen.js" /* webpackChunkName: "component---src-pages-ergoeduitzien-volwassenenbrillen-js" */),
  "component---src-pages-ergoeduitzien-zonnebrillen-js": () => import("./../../../src/pages/ergoeduitzien/zonnebrillen.js" /* webpackChunkName: "component---src-pages-ergoeduitzien-zonnebrillen-js" */),
  "component---src-pages-goedzien-js": () => import("./../../../src/pages/goedzien.js" /* webpackChunkName: "component---src-pages-goedzien-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

